import React from "react";
import { Link as Scroll } from "react-scroll";

export default class About extends React.Component {
  render() {
    return (
      <div className="aboutBody" name="about">
        <div className="aboutContainer">
          <div className="row about">
            <div className="sectionTitle">
              <span>About Me</span>
            </div>

            <div className="aboutPic col-lg-6">
              <img src="/images/portrait.png" />
            </div>
            <div className="aboutContent col-lg-6 col-12">
              <div className="aboutDetails">
                <h2>I'm Shane! An Application Developer.</h2>
                <p>
                  I'm a self taught programmer, with a Bachelors degree in
                  Geological Oceanography. I'm an avid learner and continuously
                  seek opportunities to challenge myself. I strive to bring
                  value and efficiency to each project I engage with.
                </p>
                <p>
                  During my time in the industrial controls field I began
                  teaching myself Python, and JavaScript to help solve problems
                  my employer was facing. I was able to develop scipts that
                  helped automated data entry processes as well as dashboards
                  for monitoring remote sensors. These new skills proved to be a
                  catalyst for change and the business quickly embraced the
                  potentional impact of my applications.
                </p>
                <p>
                  My time as an Application Developer has allowed me to develop
                  strong backend and frontend development skills that have since
                  been used to build a handful of geospatial applications. These
                  applications were created to convey mission critical
                  information to our end users.
                </p>
                <p>
                  I believe that we're lucky enough to live in a time where
                  nearly infinite learning resources are available. I thrive on
                  using these resourcess to explore new concepts and broaden my
                  knowledge base.
                </p>
                <p>
                  {" "}
                  I'll never stop learning and I'll always continue to create.
                </p>
                <div className="aboutScroll">
                  <Scroll to="skills" spy={false} smooth={true} duration={500}>
                    <p>
                      <strong>Check Out My Skills</strong>
                    </p>
                  </Scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
