import React from "react";

export const Projects = [
  {
    videoTag: "/gifs/surfRating_script.mp4",
    projectTitle: "Data Scraping",
    objective: "Retrieve and store surf report data from offshore Buoy's",
    firstLink: "https://github.com/occurance-now/surf-report",
    firstLinkTitle: "Github",
    secondLink: "https://github.com/occurance-now/surf-report",
    secondLinkTitle: "Github",
    technologiesUsed: "Python, Google-API's, HTML, Selenium, BeautifulSoup",
    experience:
      "I've discovered two passions in life: programming, and surfing. The most import \
      tool in a surfers belt is the surf forecast. The National Oceanic and Atmospheric Administration or NOAA \
      for short offers realtime surf related data from its offshore buoys. My goal with this project \
      was to retrieve the data from NOAA's website and move it to a Google Sheets file. \
      This project would provide me with two major benefits. The first being I would no longer \
      need to use NOAA's combersome website. The second being: surf report data storage. Overall \
      the project was a success and even had one unforeseen benefit. That is, I was able to match \
      match the daily buoy data recording with a 1-5 visual surf rating from checking live onshore surf cameras. The \
      buoy data became my independent variable and my surf rating became dependent variable. Maybe one day \
      I can use this to predict surf ratings using machine learning techniques. ",
  },
  {
    videoTag: "/gifs/PMI.mp4",
    projectTitle: "Place Making Institute",
    objective: "Create a web application containing interactive map elements.",
    firstLink: "https://placemakinginstitute.org/",
    firstLinkTitle: "Place Making Institute Web Page",
    secondLink: "",
    secondLinkTitle: "",
    technologiesUsed: "ReactJS, HTML, SASS, JavaScript",
    experience:
      "This was my second freelance website development contract. The contract \
      came to me from an acquaintance at a dinner party. His associate was interested \
      in creating a website for a newly founded co-op called The Place Making Institute. \
      The client wanted specific user interfaces for visitors on the website. ReactJS \
      was the perfect Non-CMS solution for this problem. With ReactJS I was able \
      to find specific open source components that would satisfy the specs of my client. \
      The client also had a specific deadline. This was a perfect opportunity for myself \
      to bring in another web developer and work as team. We split the responsibilities \
      evenly and were able to deliver the product within our dealine. Our client \
      was satisfied and awarded me the opportunity of continual management of their site.",
  },
  {
    videoTag: "/gifs/reddit_keyword.mp4",
    projectTitle: "Reddit Keyword Counter",
    objective: "User Reddit API's to count usage of keywords in threads.",
    firstLink: "https://github.com/occurance-now/Reddit-Keyword",
    firstLinkTitle: "Github",
    secondLink: "",
    secondLinkTitle: "",
    technologiesUsed: "Python, Kivy, Reddit",
    experience:
      "The popular thread 'WallStreetBets' on the Reddit has millions \
    of followers who everyday predict the next day's stock movement. My goal with \
    this project was to create a graphical user interface for my computer that would \
    allow me to search for a specific word in the comments of a Reddit thread and then \
    return the amount of times the word was used in the comments. The most difficult \
    part of this project turned out being creating the graphical user interface. \
    Kivy was a new framework for me and I encountered some trouble with the design \
    portion of the framework. In all the project turned out to be a success. I was \
    able to create a neat GUI for my computer with the basic functionality that \
    I was hoping to achieve. The Reddit Keyword Counter was able to tell me how many \
    times words such as 'red' or 'green' were used in the next day stock predictions. \
    Allowing me to get a general concensus of the commentors opinion on stock movements.",
  },
  {
    videoTag: "/gifs/thesocial.mp4",
    projectTitle: "The Social",
    objective:
      "Create a web application with Admin privileges that allow for \
    easy swapping of photos and text.",
    firstLink: "https://github.com/occurance-now/The-Social-RI",
    firstLinkTitle: "Github",
    secondLink: "https://www.thesocialri.com/",
    secondLinkTitle: "The Social-RI",
    technologiesUsed: "Python, Django, HTML, CSS, sqlLite",
    experience:
      "This was my very first website that I was contracted to build. My client \
      wanted a website to display his newly founded art studio called The Social RI. \
      The client had very specific design concepts in mind. They wanted their website \
      design to be simple and in some fashion to look like a website of the early 2000's. \
      In addition they had requested the ability for admins to be able to swap \
      out the pictures, captions, and titles throughout the website. Although this \
      could have been accomplished with a CMS like Wordpress, my client requested \
      it be done from scratch to keep to the 'of the 2000s' design concept. After \
      some researched I decided the Django Python framework would be a perfect tool \
      for creating this project. Coming strictly from a HTML/CSS background I quickly \
      took a very strong liking to Django. It's user functions coupled with it's \
      backend sqlLite databases worked perfectly for this project. I was able to \
      provide my client with user credentials that allowed him to login and edit \
      the webpages backend just like requested.",
  },
  {
    videoTag: "/gifs/accounts.mp4",
    projectTitle: "Remote Tankscan GUI",
    objective: "Create a GUI to monitor remote measuring devices.",
    firstLink: "https://github.com/occurance-now/accounts-tankscan",
    firstLinkTitle: "Github",
    secondLink: "",
    secondLinkTitle: "",
    technologiesUsed: "Python, Kivy, API, Field Electronics",
    experience:
      "My employer had recently purchased a tank used to hold liquid located hours \
      offsite. Immediately we realized the need to monitor the level in this tank. \
      We utilized a companies remote monitoring device to accomplish this task. \
      Although the company provided a web app that communicated with the level \
      transmitter via API's for remote monitoring, my employer wanted a slimmed \
      down GUI version for desktop. The company was kind enough to offer the API's \
      to us free of charge so we could build our own monitoring application. Quickly \
      I realized using the Python framework Kivy would give us all the capability \
      we needed to complete this project.",
  },
  {
    videoTag: "/gifs/job_schedular.mp4",
    projectTitle: "Job Scheduler",
    objective:
      "Create a command line tool equipped with read/write text file \
    abilities that allows users to schedule daily jobs.",
    firstLink: "https://github.com/occurance-now/Schedular",
    firstLinkTitle: "Github",
    secondLink: "",
    secondLinkTitle: "",
    technologiesUsed: "Python",
    experience:
      "This was project was part of the 'Python: Algorithims and Data Structures' \
      course from Udemy. The course was easily my favorite I've taken through Udemy. \
      It covered topics such as hash tables, linked list, sorting algorithims, and \
      binary search tree's. For my job scheduler project I used a binary search tree \
      to organize and sort the job schedule data. Using a binary search tree for this \
      project provided benefits such as organization, and search speed. The job sceduler \
      command line tool has the ability to read job names, start times, end times, \
      and duration from a .txt file. The job schedular then orders the jobs for you and \
      notifies you if any of the jobs can not be loaded do to overlaping job times. \
      Then the user has the ability to add or remove jobs. Once the command line tool \
      is terminated the program writes the updated job schedule back to a .txt file.",
  },
];
