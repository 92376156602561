import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SideBarData } from "./SideBarData";
import { IconContext } from "react-icons";
import { ProgressBar } from "react-bootstrap";
import { Link as Scroll } from "react-scroll";
import { FiDownload } from "react-icons/fi";
import { AiOutlineDatabase } from "react-icons/ai";

const Header = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  return (
    <React.Fragment>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <div className="navbar-on">
            <Link to="#" className="menu-bars">
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
          </div>
          <button
            className="btn-solidHeader"
            type="submit"
            value="submit"
            name="submit"
            id="mc-embedded-subscribe"
          >
            <a
              href="../assets/ResumePortfolio.pdf"
              download="ResumePortfolio.pdf"
              style={{ textDecoration: "none" }}
            >
              Download Resume
            </a>
            <span>
              <FiDownload />
            </span>
          </button>
          <div className="resumeButton">
            <div className={`rn-progress-bar ${props.ProgressStyle}`}>
              <div className="single-progress custom-color--1">
                <h6 className="title">Current Objective: Cesium and Unity</h6>
                <ProgressBar now={23} />
                <span className="label">23%</span>
              </div>
            </div>
          </div>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <div to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </div>
            </li>
            <li className="navbarPhoto">
              <img src="/images/circle-cropped75.png" />
            </li>
            {SideBarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Scroll
                    to={item.path}
                    spy={false}
                    smooth={true}
                    duration={800}
                    style={{ color: "#fff" }}
                    offset={-50}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </Scroll>
                </li>
              );
            })}
            <li className="nav-text">
              <a
                href="../assets/ResumePortfolio.pdf"
                download="ResumePortfolio.pdf"
                style={{ textDecoration: "none" }}
              >
                <FiDownload />
                <span>Resume</span>
              </a>
            </li>
            <li className="navbar-toggle">
              <div className="socialBar">
                <div className="menu-socialBars">
                  <a
                    target="_blank"
                    href="https://github.com/occurance-now"
                    rel="noopener noreferrer"
                  >
                    <AiIcons.AiFillGithub />
                  </a>
                </div>
                <div className="menu-socialBars">
                  <a
                    target="_blank"
                    href="https://linkedin.com/in/shane-palmer-8711331a7"
                    rel="noopener noreferrer"
                  >
                    <AiIcons.AiOutlineLinkedin />
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </React.Fragment>
  );
};

export default Header;
