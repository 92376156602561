import React from "react";
import { Row, Col } from "react-simple-flex-grid";
import "react-simple-flex-grid/lib/main.css";
import Collapsible from "react-collapsible";
import { BsChevronUp } from "react-icons/bs";
import { BsChevronDown } from "react-icons/bs";
import { Link as Scroll } from "react-scroll";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { Projects } from "./Projects";
import { ProjectsRow } from "./ProjectsRow";
import { ProjectsRow2 } from "./ProjectsRow2";

export class portfolio extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showing: true, value: 0 };
    this.scrollTo = this.scrollTo.bind(this);
  }

  scrollTo(showing) {
    scroller.scrollTo("projectArea", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  render() {
    const { showing, value } = this.state;

    return (
      <div className="portfolioBody" id="portfolio">
        <div className="portfolioContainer">
          <div className="portfolioTitle">
            <span>Projects:</span>
          </div>

          <div className="row RowProject1 justify-content-between">
            {ProjectsRow.map((value, index) => (
              <div className="project col-xs-6 col-md-4" key={index}>
                <div className="projectsquare">
                  <div className="projectPhoto">
                    <div
                      className="projectPhotoImg"
                      style={{
                        backgroundImage:
                          "url(" + `${value.backgroundImage}` + ")",
                      }}
                    ></div>

                    <div
                      className="middle"
                      onClick={() =>
                        this.setState(
                          { showing: !showing, value: index },
                          this.scrollTo
                        )
                      }
                    >
                      <div className="text">
                        <a>Click Here to Learn More</a>
                      </div>
                    </div>
                  </div>

                  <Collapsible
                    trigger={value.projectsRowTitle}
                    triggerTagName="TriggerTag"
                    transitionTime={400}
                    easing={"cubic-bezier(0.175, 0.885, 0.32, 2.275)"}
                    overflowWhenOpen="visible"
                  >
                    <div className="projectContent">
                      <p>{value.projectsRowContent}</p>
                    </div>
                  </Collapsible>
                </div>
              </div>
            ))}
          </div>

          {/* START PROJECTS ROW 2*/}

          <div className="row RowProject2">
            {ProjectsRow2.map((value, index) => (
              <div className="project col-xs-6 col-md-4" key={index}>
                <div className="projectsquare">
                  <div className="projectPhoto">
                    <div
                      className="projectPhotoImg"
                      style={{
                        backgroundImage:
                          "url(" + `${value.backgroundImage}` + ")",
                      }}
                    ></div>

                    <div
                      className="middle"
                      onClick={() =>
                        this.setState(
                          { showing: !showing, value: 3 + index },
                          this.scrollTo
                        )
                      }
                    >
                      <div className="text">
                        <a>Click Here to Learn More</a>
                      </div>
                    </div>
                  </div>

                  <Collapsible
                    trigger={value.projectsRowTitle}
                    triggerTagName="TriggerTag"
                    transitionTime={400}
                    easing={"cubic-bezier(0.175, 0.885, 0.32, 2.275)"}
                    overflowWhenOpen="visible"
                  >
                    <div className="projectContent">
                      <p>{value.projectsRowContent}</p>
                    </div>
                  </Collapsible>
                </div>
              </div>
            ))}
          </div>
        </div>

        {showing ? null : (
          <div className="showingContainer" name="scroll-to-element">
            <div className="projectAreaBody" id="projectArea">
              <div className="projectAreaContainer">
                <div className="projectAreaTitle">
                  <span>{Projects[value].projectTitle}</span>
                </div>
                <div className="projectVideoContainer row">
                  <div className="projectVideo col-lg-12">
                    <video className="videoTag" autoPlay loop muted>
                      <source src={Projects[value].videoTag} type="video/mp4" />
                    </video>
                  </div>
                  {/*
                  <div className="projectVideo col-lg-6">
                    <video className="videoTag2" autoPlay loop muted>
                      <source src="" type="video/mp4" />
                    </video>
                  </div>
                  */}
                </div>
                <div className="projectOverview">
                  <h2>Objective</h2>

                  <p>{Projects[value].objective}</p>

                  <h2>Links:</h2>
                  <div className="projectLinks">
                    <ul>
                      <li>
                        <a
                          href={Projects[value].firstLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {Projects[value].firstLinkTitle}
                        </a>
                      </li>
                      <li>
                        <a
                          href={Projects[value].secondLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {Projects[value].secondLinkTitle}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h2>Technologies Used</h2>
                  <p>{Projects[value].technologiesUsed}</p>
                  <h2>Experience</h2>
                  <p>{Projects[value].experience}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default portfolio;
