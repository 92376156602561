import React from "react";

export const ProjectsRow2 = [
  {
    backgroundImage: "/images/projects/thesocial_snippet.PNG",
    projectsRowTitle: "Web Development: The Social",
    projectsRowContent:
      "My first freelance web development contact. The Social RI wanted a specific \
      basic web design with the ability for the owner to swap images and texts in and out.",
  },
  {
    backgroundImage: "/images/projects/tankscan.PNG",
    projectsRowTitle: "Remote Tankscan GUI",
    projectsRowContent:
      "Newport Biodiesel my daily employer installed a tank level measuring instrument offsite.\
      I used the instruments built in API's to create a useful GUI for my co-workers \
      to monitor tank level. ",
  },
  {
    backgroundImage: "/images/projects/job_schedular.PNG",
    projectsRowTitle: "Job Scheduler",
    projectsRowContent:
      "Part of my Python Algorithims and Data Structure Udemy course. Command line \
      job scheduler that uses a binary search tree data structure for lightning execuition.",
  },
];
