import React from "react";
import { Link as Scroll } from "react-scroll";

const csSkills = [
  { skill: "Python" },
  { skill: "React.JS" },
  { skill: "Javascript" },
  { skill: "JIRA & Confluence" },
  { skill: "MongoDB" },
  { skill: "Git" },
  { skill: "Django" },
  { skill: "MQTT/Node-Red" },
  { skill: "Unit Testing" },
  { skill: "Microsoft Azure" },
  { skill: "RESTful APIs" },
];

const personalSkills = [
  { skill: "Problem Solving" },
  { skill: "Critical Thinking" },
  { skill: "Dedication" },
  { skill: "Methodical" },
  { skill: "Time Managemant" },
  { skill: "Adaptability" },
  { skill: "Meticulous" },
  { skill: "Collaboration" },
  { skill: "Organization" },
  { skill: "Planning" },
];

const physicalSkills = [
  {
    skill: "Control Systems:",
    value1: "-Comissioning",
    value2: "-Troubleshooting",
    value3: "-Logic progamming & HMI design",
  },
  {
    skill: "Electrical:",
    value1: "-High & low voltage circuits",
    value2: "-Process monitoring instrumentation",
    value3: "-Install, maintain, and repair industrial electrical systems",
  },
  {
    skill: "Plumbing:",
    value1: "-Pipe fitting large scale fluid processes",
    value2: "-Iron, stainless steel, and PVC",
    value3: "-Planning and original designing of facility additions",
  },
];

export default class Skills extends React.Component {
  render() {
    return (
      <div className="skillsBody" name="skills">
        <div className="skillsContainer">
          <div className="row skills">
            <div className="skillsTitle">
              <span>Skills</span>
            </div>

            <div className="skillsContentLeft col-lg-6 col-12">
              <div className="skillsDetails">
                <h2>Computer Science</h2>
                {csSkills.map((value, index) => (
                  <ul className="skillList">
                    <li>{value.skill}</li>
                  </ul>
                ))}
                <div className="skillsDownloadResume">
                  <a
                    href="../assets/ResumePortfolio.pdf"
                    download="ResumePortfolio.pdf"
                  >
                    Download Resume
                  </a>
                </div>
              </div>
            </div>
            <div className="skillsContent col-lg-6 col-12">
              <div className="skillsDetailsCenter">
                <h2>Soft Skills</h2>
                {personalSkills.map((value, index) => (
                  <ul className="skillList">
                    <li>{value.skill}</li>
                  </ul>
                ))}
                <div className="skillsScroll">
                  <Scroll
                    to="portfolio"
                    spy={false}
                    smooth={true}
                    duration={500}
                  >
                    <p>
                      <strong>Check Out My Projects</strong>
                    </p>
                  </Scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
