import React from "react";
import Particles from "react-tsparticles";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import * as AiIcons from "react-icons/ai";
import { Link as Scroll } from "react-scroll";

function TitlePage() {
  return (
    <div className="welcomeContainer" id="home">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-8 order-2 order-lg-1">
            <div className="welcome">
              <div className="welcomeMessage">
                <p>
                  Hello, my name is <strong>Shane Palmer</strong>.
                </p>

                <p>
                  I'm an Application Developer<br></br> and travel enthusiast.
                </p>
              </div>
              <div className="welcomeNavContainer">
                <div className="welcomeNav">
                  <Scroll to="about" spy={false} smooth={true} duration={500}>
                    <p>
                      <strong>About Me</strong>
                    </p>
                  </Scroll>
                </div>

                <div className="welcomeNav">
                  <Scroll to="contact" spy={false} smooth={true} duration={500}>
                    <p>
                      <strong>Contact</strong>
                    </p>
                  </Scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="welcomeBackground">
        <Particles
          id="tsparticles"
          options={{
            background: {
              color: {
                value: "#0d1a26",
              },
            },
            fpsLimit: 60,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onClick: {
                  enable: true,
                  mode: "repulse",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 500,
                  duration: 2,
                  opacity: 0.9,
                  size: 40,
                },
                push: {
                  quantity: 1,
                },
                repulse: {
                  distance: 50,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#FFFFFF",
              },
              links: {
                color: "#33CCFF",
                distance: 100,
                enable: true,
                opacity: 0.3,
                width: 0.5,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 0.5,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 400,
                },
                value: 130,
              },
              opacity: {
                value: 0.6,
              },
              shape: {
                type: "cobweb",
              },
              size: {
                random: true,
                value: 3,
              },
            },
            detectRetina: true,
          }}
        />
      </div>
    </div>
  );
}

export default TitlePage;
