import React from "react";

export const ProjectsRow = [
  {
    backgroundImage: "/images/projects/NOAA_capture.PNG",
    projectsRowTitle: "Data Scraping: Surf Report",
    projectsRowContent:
      "Using data scraping Python tools to record offshore buoy measurements as\
      provided by the National Oceanic and Atmospheric Administration",
  },
  {
    backgroundImage: "/images/projects/pmi_snippet.PNG",
    projectsRowTitle: "Web Development: Place Making Institute",
    projectsRowContent:
      "My second freelance website contract. The Place Making Institute \
      focuses on serving historic towns and protecting the authenticity of the towns.",
  },
  {
    backgroundImage: "/images/projects/reddit_keyword.PNG",
    projectsRowTitle: "Reddit Keyword Counter",
    projectsRowContent:
      "Ever wonder how many times a word is used in reddit thread? I created this to \
      track consensus predictions on upcoming stock movements. ",
  },
];
