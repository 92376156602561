/**************************************
    Import Packages
***************************************/

import React from "react";
import "./index.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Particles from "react-tsparticles";

/**************************************
    Import Components
***************************************/

import TitlePage from "./components/titlePage/TitlePage";
import Portfolio from "./components/pages/Portfolio";
import Contact from "./components/pages/Contact";
import Header from "./components/layout/header";
import About from "./components/pages/About";
import Skills from "./components/pages/Skills";
import Footer from "./components/layout/Footer";

class App extends React.Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <TitlePage />
          <About />
          <Skills />
          <Portfolio />
          <Contact />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
