import React from "react";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import emailjs from "emailjs-com";
import { Message, Form } from "semantic-ui-react";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: false,
      showing: true,
    };

    this.sendEmail = this.sendEmail.bind(this);
  }

  sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6rzsf6r",
        "template_qk55m8w",
        e.target,
        "user_vtzyXW5KgDfbu6eaFM9Lm"
      )
      .then(
        (result) => {
          this.setState({ formError: true });
          this.setState({ showing: false });
        },
        (error) => {
          this.setState({ formError: false });
          this.setState({ showing: false });
        }
      );
    e.target.reset();
  }
  render() {
    const { formError, showing } = this.state;
    return (
      <div className="contactBody" name="contact">
        <div className="contactContainer">
          <div className="contactTitle">
            <span>Contact</span>
          </div>
          <div className="row contact justify-content-between">
            <div
              className="col-lg-4 col-md-6 col-sm-12 col-12"
              style={{ marginBottom: "5%" }}
            >
              <div className="rn-address">
                <div className="icon">
                  <FiHeadphones />
                </div>
                <div className="inner">
                  <h4
                    className="title"
                    style={{ fontFamily: "raleway, sans-serif" }}
                  >
                    Phone Number
                  </h4>
                  <p>
                    <a href="tel:617 491 8952">+1 (774)-365-0888</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div
              className="col-lg-4 col-md-6 col-sm-12 col-12 mt_mobile--50"
              style={{ marginBottom: "5%" }}
            >
              <div className="rn-address">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4
                    className="title"
                    style={{ fontFamily: "raleway, sans-serif" }}
                  >
                    Email Address
                  </h4>
                  <p>
                    <a href="shanepa94@gmail.com">shanepa94@gmail.com</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div
              className="col-lg-4 col-md-6 col-sm-12 col-12 mt_md--50 mt_sm--50"
              style={{ marginBottom: "5%" }}
            >
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin />
                </div>
                <div className="inner">
                  <h4
                    className="title"
                    style={{ fontFamily: "raleway, sans-serif" }}
                  >
                    Location
                  </h4>
                  <p>Newport, Rhode Island 02840</p>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-form--1">
            <div className="container">
              <div className="row row--35 align-items-start">
                <div className="col-lg-12 order-2 order-lg-1">
                  <div className="section-title text-left mb--50">
                    <h2 className="title">Contact Me.</h2>
                    <p className="description">
                      I'd love to hear from you.<br></br> Please fill out the
                      form below and I'll be in contact with you shortly.
                    </p>
                  </div>
                  <div className="form-wrapper">
                    <form onSubmit={this.sendEmail}>
                      <label htmlFor="item01">
                        <input
                          type="text"
                          name="name"
                          id="item01"
                          placeholder="Your Name *"
                        />
                      </label>

                      <label htmlFor="item02">
                        <input
                          type="text"
                          name="email"
                          id="item02"
                          placeholder="Your email *"
                        />
                      </label>

                      <label htmlFor="item03">
                        <input
                          type="text"
                          name="subject"
                          id="item03"
                          placeholder="Write a Subject"
                        />
                      </label>
                      <label htmlFor="item04">
                        <textarea
                          type="text"
                          id="item04"
                          name="message"
                          placeholder="Your Message"
                        />
                      </label>
                      <button
                        className="btn-solid"
                        type="submit"
                        value="submit"
                        name="submit"
                        id="mc-embedded-subscribe"
                      >
                        Submit
                      </button>
                      {showing ? null : formError ? (
                        <Message
                          positive
                          header="Thanks for getting in touch!"
                          content="Your message was successfully sent."
                        />
                      ) : (
                        <Message
                          negative
                          header="Sorry something went wrong on our end. \
                          please contact me using another method."
                          list={[""]}
                        />
                      )}
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="backToTop">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
      </div>
    );
  }
}
