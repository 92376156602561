import React from "react";
import { FiDownload } from "react-icons/fi";
import * as AiIcons from "react-icons/ai";
export default class Footer extends React.Component {
  render() {
    return (
      <div className="footerBody" name="footer">
        <div className="footerContainer">
          <div className="footer row">
            <div
              className="footerConnect col-6"
              style={{ display: "inline-block" }}
            >
              <h2>Lets Connect</h2>
              <div className="footerLinks">
                <a
                  target="_blank"
                  href="https://github.com/occurance-now"
                  rel="noopener noreferrer"
                >
                  <AiIcons.AiFillGithub />
                </a>
                <a
                  target="_blank"
                  href="https://linkedin.com/in/shane-palmer-8711331a7"
                  rel="noopener noreferrer"
                >
                  <AiIcons.AiOutlineLinkedin />
                </a>
              </div>
            </div>
            <div className="footerConnect2 col-6">
              <button
                className="btn-solidFooter"
                type="submit"
                value="submit"
                name="submit"
                id="mc-embedded-subscribe"
              >
                <a
                  href="../assets/ResumePortfolio.pdf"
                  download="ResumePortfolio.pdf"
                  style={{ textDecoration: "none" }}
                >
                  Download Resume
                </a>
                <span>
                  <FiDownload />
                </span>
              </button>
              <div className="FooterRights">
                <p>@ 2021 Shane Palmer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
